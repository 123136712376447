import { BarChartOutlined , DeleteOutlined ,  NodeCollapseOutlined, NodeExpandOutlined , FunnelPlotOutlined, UpOutlined, DownOutlined, TableOutlined, BulbOutlined , PieChartOutlined, LineChartOutlined, CaretUpOutlined, DotChartOutlined, BarsOutlined, CaretDownOutlined } from '@ant-design/icons';

import area from './chart-icons/area-chart.png'
import bar from './chart-icons/bar-graph.png'
// import bubble from './chart-icons/bubble-chart.png'
import donut from './chart-icons/donut-chart.png'
import funnel from './chart-icons/funnel-chart.png'
import gauge from './chart-icons/gauge-chart.png'
import line from './chart-icons/line-graph.png'
import pie from './chart-icons/pie-chart.png'
import stackedBar from './chart-icons/stacked-bar-chart.png'

export const chartIcons = [
    { "name": "line", "icon": line },
    { "name": "area", "icon": area },
    // { "name": "bubble", "icon": bubble },
    { "name": "bar", "icon": bar },
    { "name": "stackedBar", "icon": stackedBar },
    { "name": "pie", "icon": pie },
    { "name": "donut", "icon": donut },
    { "name": "funnel", "icon": funnel },
    // { "name": "gauge", "icon": gauge, "cs":true },
    // { "name": "gauge", "icon": gauge, "cs":true },
  ]

export const defaultConfigSetting = {
    donutSize: 4,
    reverseFunnel: false,
    yaxis: [],
    xaxis: [],
    lineWidth: 1,
    lineMarker: 'none'
}

export const defaultDataToExplore = [
  [
    {
      "value": "Name"
    },
    {
      "value": "Age"
    },
    {
      "value": "Weight"
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": "Sanjay"
    },
    {
      "value": "23"
    },
    {
      "value": "100"
    }
  ],
  [
    {
      "value": "John"
    },
    {
      "value": "44"
    },
    {
      "value": "56"
    }
  ],
  [
    {
      "value": "Vicky"
    },
    {
      "value": "21"
    },
    {
      "value": "79"
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ],
  [
    {
      "value": ""
    },
    {
      "value": ""
    },
    {
      "value": ""
    }
  ]
]
export const colorsList = {
    "smooth":
    {
        "smooth 1": [
            { name: "Soft Blue", code: "#6A7B8D" },
            { name: "Light Pink", code: "#E5989B" },
            { name: "Pale Yellow", code: "#FDEBD0" },
            { name: "Lavender", code: "#BDC3C7" },
            { name: "Mint Green", code: "#82E0AA" },
            { name: "Peach", code: "#FFCC99" },
            { name: "Lilac", code: "#C39BD3" },
            { name: "Light Gray", code: "#D0D3D4" },
            { name: "Soft Purple", code: "#A29AC1" },
            { name: "Pale Green", code: "#D5E8D4" },
            { name: "Pastel Orange", code: "#FFB347" },
            { name: "Baby Blue", code: "#AED6F1" },
            { name: "Cream", code: "#F4E1DE" },
            { name: "Sky Blue", code: "#87CEEB" },
            { name: "Coral Pink", code: "#F08080" },
            { name: "Dusty Rose", code: "#D8A7B1" },
            { name: "Soft Turquoise", code: "#AFEEEE" },
            { name: "Ivory", code: "#FFFFF0" },
            { name: "Seafoam Green", code: "#90EE90" },
            { name: "Pearl", code: "#EAE0C8" }
        ],

        "smooth 2": [
            { name: "Soft Lavender", code: "#E9D8FD" },
            { name: "Blush Pink", code: "#FFC6D9" },
            { name: "Buttercream", code: "#FFF8BA" },
            { name: "Pale Coral", code: "#FFD3B5" },
            { name: "Seafoam Blue", code: "#A2D2FF" },
            { name: "Warm Taupe", code: "#CBBFAD" },
            { name: "Misty Gray", code: "#D8D8D8" },
            { name: "Soft Lilac", code: "#E2C5FF" },
            { name: "Pale Mint", code: "#B9FFCB" },
            { name: "Champagne", code: "#F7E7CE" },
            { name: "Powder Blue", code: "#BBD6F7" },
            { name: "Apricot", code: "#FFD08A" },
            { name: "Pearl White", code: "#FCF9E3" },
            { name: "Dusty Rose", code: "#E8B6D4" },
            { name: "Sage Green", code: "#B8E1A7" },
            { name: "Soft Peach", code: "#FFD4B9" },
            { name: "Icy Blue", code: "#B3E5FC" },
            { name: "Warm Ivory", code: "#FFF7E2" },
            { name: "Silver Gray", code: "#C0C0C0" },
            { name: "Pale Lemon", code: "#FFF9C4" }
        ]

    },
    "shade": {

        "Midnight Blue":
            [
                { name: "Blue 1", code: "#0074d9" },
                { name: "Blue 2", code: "#1a82dd" },
                { name: "Blue 3", code: "#3390e1" },
                { name: "Blue 4", code: "#4d9ee4" },
                { name: "Blue 5", code: "#66ace8" },
                { name: "Blue 6", code: "#80baec" },
                { name: "Blue 7", code: "#99c7f0" },
                { name: "Blue 8", code: "#b3d5f4" },
                { name: "Blue 9", code: "#cce3f7" },
                { name: "Blue 10", code: "#e6f1fb" },
            ],


        "Midnight Grey": [
            { name: "Grey 1", code: "#808080" },
            { name: "Grey 2", code: "#8d8d8d" },
            { name: "Grey 3", code: "#999999" },
            { name: "Grey 4", code: "#a6a6a6" },
            { name: "Grey 5", code: "#b3b3b3" },
            { name: "Grey 6", code: "#c0c0c0" },
            { name: "Grey 7", code: "#cccccc" },
            { name: "Grey 8", code: "#d9d9d9" },
            { name: "Grey 9", code: "#e6e6e6" },
        ],


        "Day Grey": [
            { name: "Grey 1", code: "#808080" },
            { name: "Grey 2", code: "#737373" },
            { name: "Grey 3", code: "#666666" },
            { name: "Grey 4", code: "#5a5a5a" },
            { name: "Grey 5", code: "#4d4d4d" },
            { name: "Grey 6", code: "#404040" },
            { name: "Grey 7", code: "#333333" },
            { name: "Grey 8", code: "#262626" },
            { name: "Grey 9", code: "#1a1a1a" },
        ],

        "Day Blue":
            [
                { name: "Blue 1", code: "#0074d9" },
                { name: "Blue 2", code: "#0068c3" },
                { name: "Blue 3", code: "#005dae" },
                { name: "Blue 4", code: "#005198" },
                { name: "Blue 5", code: "#004682" },
                { name: "Blue 6", code: "#003a6d" },
                { name: "Blue 7", code: "#002e57" },
                { name: "Blue 8", code: "#002341" },
                { name: "Blue 9", code: "#00172b" },
                { name: "Blue 10", code: "#000c16" },
            ],

    }
}

export const gradients = {
  "gradient":[
    { name: "Default",  gradient: 'linear-gradient(135deg, #222222, #444444)',theme:'dark' , },
    { name: 'Night Sky', gradient: 'linear-gradient(135deg, #1a1a1a, #222222)', theme:'dark' ,},
    { name: 'Dark Ocean', gradient: 'linear-gradient(135deg, #222222, #2c3e50)', theme:'dark' ,},
    { name: 'Charcoal', gradient: 'linear-gradient(135deg, #2c3e50, #343434)', theme:'dark' ,},
    { name: 'Graphite', gradient: 'linear-gradient(135deg, #343434, #3d3d3d)', theme:'dark' ,},
    { name: 'Midnight Blue', gradient: 'linear-gradient(135deg, #2c3e50, #34495e)', theme:'dark' ,},
    { name: 'Deep Purple', gradient: 'linear-gradient(135deg, #34495e, #7f8c8d)', theme:'dark' ,},
    { name: 'Dark Slate', gradient: 'linear-gradient(135deg, #2c3e50, #95a5a6)', theme:'dark' ,},
    { name: 'Night Sky', gradient: 'linear-gradient(135deg, #1a1a1a, #303030)', theme:'dark' ,},
    { name: 'Dark Olive', gradient: 'linear-gradient(135deg, #6e6e6e, #556b2f)', theme:'dark' ,},
    { name: 'Deep Forest', gradient: 'linear-gradient(135deg, #2c3e50, #4b5320)', theme:'dark' ,},
    { name: 'Burgundy', gradient: 'linear-gradient(135deg, #2c3e50, #800000)', theme:'dark' ,},
    { name: 'Wine Red', gradient: 'linear-gradient(135deg, #2c3e50, #8b0000)', theme:'dark' ,},
    { name: 'Dark Coral', gradient: 'linear-gradient(135deg, #2c3e50, #8b4513)', theme:'dark' ,},
    { name: 'Mahogany', gradient: 'linear-gradient(135deg, #2c3e50, #420d09)', theme:'dark' ,},
    { name: 'Dark Indigo', gradient: 'linear-gradient(135deg, #2c3e50, #191970)', theme:'dark' ,},
    { name: 'Deep Violet', gradient: 'linear-gradient(135deg, #2c3e50, #4b0082)', theme:'dark' ,},
    { name: 'Slate Blue', gradient: 'linear-gradient(135deg, #2c3e50, #6a5acd)', theme:'dark' ,},
    { name: 'Navy Blue', gradient: 'linear-gradient(135deg, #2c3e50, #000080)', theme:'dark' ,},
    { name: 'Royal Purple', gradient: 'linear-gradient(135deg, #2c3e50, #800080)', theme:'dark' ,},
    { name: 'Dark Magenta', gradient: 'linear-gradient(135deg, #2c3e50, #8b008b)', theme:'dark' ,},
    { name: 'Deep Coral', gradient: 'linear-gradient(135deg, #2c3e50, #ff1493)', theme:'dark' ,},
    { name: 'Maroon', gradient: 'linear-gradient(135deg, #2c3e50, #800000)', theme:'dark' ,},
    { name: 'Midnight Blue', gradient: 'linear-gradient(135deg, #2c3e50, #34495e)', theme:'dark' ,},
    { name: 'Deep Purple', gradient: 'linear-gradient(135deg, #34495e, #7f8c8d)', theme:'dark' ,},
    { name: 'Dark Slate', gradient: 'linear-gradient(135deg, #2c3e50, #95a5a6)', theme:'dark' ,},
    { name: 'Night Sky', gradient: 'linear-gradient(135deg, #1a1a1a, #303030)', theme:'dark' ,},
    { name: 'Steel Gray', gradient: 'linear-gradient(135deg, #555555, #6e6e6e)', theme:'dark' ,},
    { name: 'Charcoal', gradient: 'linear-gradient(135deg, #3b3b3b, #777777)', theme:'dark' ,},
    { name: 'Dark Olive', gradient: 'linear-gradient(135deg, #6e6e6e, #556b2f)', theme:'dark' ,},
    { name: 'Deep Forest', gradient: 'linear-gradient(135deg, #2c3e50, #4b5320)', theme:'dark' ,},
    { name: 'Burgundy', gradient: 'linear-gradient(135deg, #2c3e50, #800000)', theme:'dark' ,},
    { name: 'Wine Red', gradient: 'linear-gradient(135deg, #2c3e50, #8b0000)', theme:'dark' ,},
    { name: 'Dark Coral', gradient: 'linear-gradient(135deg, #2c3e50, #8b4513)', theme:'dark' ,},
    { name: 'Mahogany', gradient: 'linear-gradient(135deg, #2c3e50, #420d09)', theme:'dark' ,},
    { name: 'Dark Indigo', gradient: 'linear-gradient(135deg, #2c3e50, #191970)', theme:'dark' ,},
    { name: 'Deep Violet', gradient: 'linear-gradient(135deg, #2c3e50, #4b0082)', theme:'dark' ,},
    { name: 'Slate Blue', gradient: 'linear-gradient(135deg, #2c3e50, #6a5acd)', theme:'dark' ,},
    { name: 'Navy Blue', gradient: 'linear-gradient(135deg, #2c3e50, #000080)', theme:'dark' ,},
    { name: 'Royal Purple', gradient: 'linear-gradient(135deg, #2c3e50, #800080)', theme:'dark' ,},
    { name: 'Dark Magenta', gradient: 'linear-gradient(135deg, #2c3e50, #8b008b)', theme:'dark' ,},
    { name: 'Deep Coral', gradient: 'linear-gradient(135deg, #2c3e50, #ff1493)', theme:'dark' ,},
    { name: 'Maroon', gradient: 'linear-gradient(135deg, #2c3e50, #800000)', theme:'dark' ,},
    { name: 'Dark Violet', gradient: 'linear-gradient(135deg, #2c3e50, #9400D3)', theme:'dark' ,},
    { name: 'Deep Pink', gradient: 'linear-gradient(135deg, #2c3e50, #FF1493)', theme:'dark' ,},
    { name: 'Navy Blue', gradient: 'linear-gradient(135deg, #2c3e50, #000080)', theme:'dark' ,},

    { name: 'Dark Green', gradient: 'linear-gradient(135deg, #2c3e50, #006400)', theme:'dark' ,},


    { name: 'Dark Olive Green', gradient: 'linear-gradient(135deg, #2c3e50, #556B2F)', theme:'dark' ,},
    { name: 'Dark Orange', gradient: 'linear-gradient(135deg, #2c3e50, #FF8C00)', theme:'dark' ,},
    { name: 'Dark Orchid', gradient: 'linear-gradient(135deg, #2c3e50, #9932CC)', theme:'dark' ,},
    { name: 'Dark Salmon', gradient: 'linear-gradient(135deg, #2c3e50, #E9967A)', theme:'dark' ,},
    { name: 'Dark Sea Green', gradient: 'linear-gradient(135deg, #2c3e50, #8FBC8F)', theme:'dark' ,},
    { name: 'Dark Slate Blue', gradient: 'linear-gradient(135deg, #2c3e50, #483D8B)', theme:'dark' ,},
    { name: 'Dark Slate Gray', gradient: 'linear-gradient(135deg, #2c3e50, #2F4F4F)', theme:'dark' ,},
    // { name: 'Dark Turquoise', gradient: 'linear-gradient(135deg, #2c3e50, #00CED1)', theme:'dark' ,},
    { name: 'Dark Violet', gradient: 'linear-gradient(135deg, #2c3e50, #9400D3)', theme:'dark' ,},
    { name: 'Deep Pink', gradient: 'linear-gradient(135deg, #2c3e50, #FF1493)', theme:'dark' ,},
    // { name: 'Deep Sky Blue', gradient: 'linear-gradient(135deg, #2c3e50, #00BFFF)', theme:'dark' ,},
    { name: 'Forest Green', gradient: 'linear-gradient(135deg, #2c3e50, #228B22)', theme:'dark' ,},

    // { name: 'Goldenrod', gradient: 'linear-gradient(135deg, #2c3e50, #DAA520)', theme:'dark' ,},
    { name: 'Green', gradient: 'linear-gradient(135deg, #2c3e50, #008000)', theme:'dark' ,},
    { name: 'Indigo', gradient: 'linear-gradient(135deg, #2c3e50, #4B0082)', theme:'dark' ,},
    { name: 'Light Coral', gradient: 'linear-gradient(135deg, #2c3e50, #F08080)', theme:'dark' ,},
    { name: 'Light Sea Green', gradient: 'linear-gradient(135deg, #2c3e50, #20B2AA)', theme:'dark' ,},
    // { name: 'Light Sky Blue', gradient: 'linear-gradient(135deg, #2c3e50, #87CEFA)', theme:'dark' ,},
    { name: 'Light Slate Gray', gradient: 'linear-gradient(135deg, #2c3e50, #778899)', theme:'dark' ,},
    { name: 'Lime Green', gradient: 'linear-gradient(135deg, #2c3e50, #32CD32)', theme:'dark' ,},
    { name: 'Medium Aquamarine', gradient: 'linear-gradient(135deg, #2c3e50, #66CDAA)', theme:'dark' ,},
    { name: 'Medium Blue', gradient: 'linear-gradient(135deg, #2c3e50, #0000CD)', theme:'dark' ,},
    { name: 'Medium Purple', gradient: 'linear-gradient(135deg, #2c3e50, #9370DB)', theme:'dark' ,},
    { name: 'Medium Sea Green', gradient: 'linear-gradient(135deg, #2c3e50, #3CB371)', theme:'dark' ,},
    { name: 'Medium Slate Blue', gradient: 'linear-gradient(135deg, #2c3e50, #7B68EE)', theme:'dark' ,},
    // { name: 'Medium Spring Green', gradient: 'linear-gradient(135deg, #2c3e50, #00FA9A)', theme:'dark' ,},
    { name: 'Medium Violet Red', gradient: 'linear-gradient(135deg, #2c3e50, #C71585)', theme:'dark' ,},
    { name: 'Navajo White', gradient: 'linear-gradient(135deg, #2c3e50, #FFDEAD)', theme:'dark' ,},
    { name: 'Olive Drab', gradient: 'linear-gradient(135deg, #2c3e50, #6B8E23)', theme:'dark' ,},
    { name: 'Orange', gradient: 'linear-gradient(135deg, #2c3e50, #FFA500)', theme:'dark' ,},
    { name: 'Orange Red', gradient: 'linear-gradient(135deg, #2c3e50, #FF4500)', theme:'dark' ,},
    { name: 'Orchid', gradient: 'linear-gradient(135deg, #2c3e50, #DA70D6)', theme:'dark' ,},
    { name: 'Pale Violet Red', gradient: 'linear-gradient(135deg, #2c3e50, #DB7093)', theme:'dark' ,},
    { name: 'Peach Puff', gradient: 'linear-gradient(135deg, #2c3e50, #FFDAB9)', theme:'dark' ,},
    { name: 'Peru', gradient: 'linear-gradient(135deg, #2c3e50, #CD853F)', theme:'dark' ,},
    { name: 'Powder Blue', gradient: 'linear-gradient(135deg, #2c3e50, #B0E0E6)', theme:'dark' ,},
    { name: 'Rosy Brown', gradient: 'linear-gradient(135deg, #2c3e50, #BC8F8F)', theme:'dark' ,},
    { name: 'Royal Blue', gradient: 'linear-gradient(135deg, #2c3e50, #4169E1)', theme:'dark' ,},
    { name: 'Saddle Brown', gradient: 'linear-gradient(135deg, #2c3e50, #8B4513)', theme:'dark' ,},
    { name: 'Salmon', gradient: 'linear-gradient(135deg, #2c3e50, #FA8072)', theme:'dark' ,},
    { name: 'Sandy Brown', gradient: 'linear-gradient(135deg, #2c3e50, #F4A460)', theme:'dark' ,},
    { name: 'Sea Green', gradient: 'linear-gradient(135deg, #2c3e50, #2E8B57)', theme:'dark' ,},
    { name: 'Sienna', gradient: 'linear-gradient(135deg, #2c3e50, #A0522D)', theme:'dark' ,},
    { name: 'Slate Blue', gradient: 'linear-gradient(135deg, #2c3e50, #6A5ACD)', theme:'dark' ,},
    { name: 'Slate Gray', gradient: 'linear-gradient(135deg, #2c3e50, #708090)', theme:'dark' ,},
    { name: 'Steel Blue', gradient: 'linear-gradient(135deg, #2c3e50, #4682B4)', theme:'dark' ,},
    { name: 'Tan', gradient: 'linear-gradient(135deg, #2c3e50, #D2B48C)', theme:'dark' ,},
    { name: 'Teal', gradient: 'linear-gradient(135deg, #2c3e50, #008080)', theme:'dark' ,},
    { name: 'Tomato', gradient: 'linear-gradient(135deg, #2c3e50, #FF6347)', theme:'dark' ,},
    { name: 'Turquoise', gradient: 'linear-gradient(135deg, #2c3e50, #40E0D0)', theme:'dark' ,},
    { name: 'Violet', gradient: 'linear-gradient(135deg, #2c3e50, #EE82EE)', theme:'dark' ,},
    { name: 'Dark Orchid', gradient: 'linear-gradient(135deg, #2c3e50, #9932CC)', theme:'dark' ,},
    { name: 'Dark Salmon', gradient: 'linear-gradient(135deg, #2c3e50, #E9967A)', theme:'dark' ,},
    { name: 'Dark Sea Green', gradient: 'linear-gradient(135deg, #2c3e50, #8FBC8F)', theme:'dark' ,},
    { name: 'Dark Slate Blue', gradient: 'linear-gradient(135deg, #2c3e50, #483D8B)', theme:'dark' ,},
    { name: 'Dark Slate Gray', gradient: 'linear-gradient(135deg, #2c3e50, #2F4F4F)', theme:'dark' ,},
    { name: 'Dark Violet', gradient: 'linear-gradient(135deg, #2c3e50, #9400D3)', theme:'dark' ,},
    { name: 'Deep Pink', gradient: 'linear-gradient(135deg, #2c3e50, #FF1493)', theme:'dark' ,},
    { name: 'Forest Green', gradient: 'linear-gradient(135deg, #2c3e50, #228B22)', theme:'dark' ,},
    { name: 'Goldenrod', gradient: 'linear-gradient(135deg, #2c3e50, #DAA520)', theme:'dark' ,},
    { name: 'Indigo', gradient: 'linear-gradient(135deg, #2c3e50, #4B0082)', theme:'dark' ,},
    { name: 'Light Coral', gradient: 'linear-gradient(135deg, #2c3e50, #F08080)', theme:'dark' ,},
    { name: 'Light Salmon', gradient: 'linear-gradient(135deg, #2c3e50, #FFA07A)', theme:'dark' ,},
    { name: 'Light Sea Green', gradient: 'linear-gradient(135deg, #2c3e50, #20B2AA)', theme:'dark' ,},
    { name: 'Light Slate Gray', gradient: 'linear-gradient(135deg, #2c3e50, #778899)', theme:'dark' ,},
    { name: 'Medium Aquamarine', gradient: 'linear-gradient(135deg, #2c3e50, #66CDAA)', theme:'dark' ,},
    { name: 'Medium Blue', gradient: 'linear-gradient(135deg, #2c3e50, #0000CD)', theme:'dark' ,},
    { name: 'Medium Purple', gradient: 'linear-gradient(135deg, #2c3e50, #9370DB)', theme:'dark' ,},
    { name: 'Medium Sea Green', gradient: 'linear-gradient(135deg, #2c3e50, #3CB371)', theme:'dark' ,},
    { name: 'Medium Slate Blue', gradient: 'linear-gradient(135deg, #2c3e50, #7B68EE)', theme:'dark' ,},
    { name: 'Medium Turquoise', gradient: 'linear-gradient(135deg, #2c3e50, #48D1CC)', theme:'dark' ,},
    { name: 'Medium Violet Red', gradient: 'linear-gradient(135deg, #2c3e50, #C71585)', theme:'dark' ,},
  ],
  'color':
    [
    { name: 'Black', gradient: '#000000' , theme:'dark' ,},
    { name: 'Dark Gray', gradient: '#333333', theme:'dark' , },
    // { name: 'Dim Gray', gradient:'#696969' },
    // { name: 'Slate Gray', gradient:'#708090' },
    // { name: 'Gray', gradient:'#808080' },
    // { name: 'Light Gray', gradient:'#D3D3D3' },
    // { name: 'Silver', gradient:'#C0C0C0' },
    // { name: 'Gainsboro', gradient:'#DCDCDC' },
    { name: 'White', gradient: '#FFFFFF', theme:'light' , },
    // { name: 'Red', gradient:'#FF0000' },
    { name: 'Dark Red', gradient: '#8B0000' , theme:'dark' ,},
    { name: 'Maroon', gradient: '#800000' , theme:'dark' ,},
    { name: 'Tomato', gradient: '#FF6347', theme:'dark' , },
    // { name: 'Orange', gradient:'#FFA500' },
    // { name: 'Dark Orange', gradient:'#FF8C00' },
    // { name: 'Gold', gradient:'#FFD700' },
    // { name: 'Yellow', gradient:'#FFFF00' },
    { name: 'Green', gradient: '#008000', theme:'dark' , },
    { name: 'Dark Green', gradient: '#006400' , theme:'dark' ,},
    // { name: 'Lime', gradient:'#00FF00' },
    // { name: 'Spring Green', gradient:'#00FF7F' },
    // { name: 'Light Green', gradient:'#90EE90' },
    // { name: 'Cyan', gradient:'#00FFFF' },
    { name: 'Dark Cyan', gradient: '#008B8B', theme:'dark' , },
    { name: 'Teal', gradient: '#008080', theme:'dark' , },
    // { name: 'Blue', gradient:'#0000FF' },
    { name: 'Dark Blue', gradient: '#00008B', theme:'dark' , },
    { name: 'Navy', gradient: '#000080' , theme:'dark' ,},
    { name: 'Purple', gradient: '#800080', theme:'dark' , },
    { name: 'Indigo', gradient: '#4B0082', theme:'dark' , },
    { name: 'Violet', gradient: '#8A2BE2', theme:'dark' , },
    // { name: 'Magenta', gradient:'#FF00FF' },
    { name: 'Dark Magenta', gradient: '#8B008B', theme:'dark' , },
    // { name: 'Pink', gradient:'#FFC0CB' },
    ]
  }

let selectedTheme = {
    plot_color: "#00bfff",
    bg: "#222222 ",
    pbc: "#333333",
    label: "#eeeeee",
    tick: "#cccccc",
    grid: "rgba(255,255,255,.2)",
};



export const defaultLayout =  {
    series: [
      {
        "name": "Age",
        "data": [
          23,
          44,
          21
        ]
      },
    ],
    labels: [
      "Sanjay",
      "John",
      "Vicky"
    ],
    // colors:["#FF6347"],
    xaxis: {
        categories: ["Sanjay" , "John" , "Vicky"],
        title: {
            text: 'Name',
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          
          lines: {
            show: false,
          },
          labels: {
            show: true,
          },

      },
      yaxis: {
        title: {
          text: ''
        },
        opposite: true,
        reversed: false,
        axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          lines: {
            show: false,
          },
          labels: {
            show: true, 
          },
      },
    theme: {
        mode: 'light',
        monochrome: {
            enabled: true,
            color: '#FF6347',
            shadeTo: 'light',
            shadeIntensity: 0.65
          },
        // palette: 'palette3'
        
    },
    tooltip:{
      enabled:true,
      theme:'dark',
    },
    chart: {
        background: 'transparent',
        type: 'bar',      
        width: '100%', 
        height: '500px',
        toolbar:{
          show:false
        }
      
    },
    dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth'
      },
   
      title: {
        text: 'My Chart',
        align: 'left',
        // floating: true,
        //   offsetY: 330,
        //   align: 'center',
        //   style: {
        //     color: '#444'
        //   }
      },
      subtitle: {
        text: 'Subtitle',
        align: 'left'
      },
      grid: {
        row:{
          opacity: 0.1
        },
        column:{
          opacity:0.1
        },
        xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }

        // borderColor: '#e7e7e7',
        // row: {
        //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //   opacity: 0
        // },
      },
      markers: {
        size: 0
      },
      
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                
              horizontal: false,
              isFunnel:true,
              dataLabels: {
                position: 'top', // top, center, bottom
                // total: {
                //     enabled: true,
                //     style: {
                //       fontSize: '13px',
                //       fontWeight: 900
                //     }
                //   }
              },
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          fill: {
            opacity: 1
          }

  }



export const subChart = {
    "line": [
        {
            label: 'Line',
            value: 'line',

        },
        {
            label: 'Stack',
            value: 'stack',

        },
        {
            label: 'Bubble',
            value: 'scatter',

        }
    ],
    "bar": [
        {
            label: 'Grouped',
            value: 'bar',

        },
        {
            label: 'Stacked',
            value: 'stack',

        },
        {
            label: 'H - Grouped',
            value: 'h-bar',

        }
        ,
        {
            label: 'H - Stacked',
            value: 'h-stack',

        }
    ],
    "pie": [
        {
            label: "Pie",
            value: "pie"
        },
        {
            label: "Donut",
            value: "donut"
        },
        {
            label: "Funnel",
            value: "funnel"
        },
        {
            label: "Funnel Old",
            value: "funnel-traditional"
        },


    ],
    "others": [
        {
            "label": "Cards",
            "value": "cards"

        },
        {
            "label": "Word Cloud",
            "value": "wordcloud"

        },
        {
            "label": "Table",
            "value": "table"
        }
        ,
        // {
        //     "label": "Line",
        //     "value":"line"
        // }
    ]

}
export const subChartAndIcons = {
    "line": <div> <LineChartOutlined /> / <DotChartOutlined /> </div>,
    "bar": <div><BarChartOutlined /> / <BarsOutlined /></div>,
    "pie": <div> <PieChartOutlined /> / <FunnelPlotOutlined /> </div>,
}
export const chartMetaDataDefault = {
    "line": {
        "line": {
            "type": "scatter",
            "mode": "lines+markers",
            "marker": {
                symbol: 'diamond',
            },
            "line": {

            }
        },
        "scatter": {
            "type": "scatter",
            "mode": "markers",
            "marker": {
                size: 8,
                symbol: 'diamond',

                sizemode: 'area'

            }
        },
        "stack": {
            "type": "scatter",
            "fill": "tozeroy",
            "marker": {
            },
            "line": {

            }
        }
    },
    "bar": {

        "bar": {
            type: 'bar'
        },
        "h-bar": {
            type: 'bar',
            orientation: 'h'
        },

        "stack": {
            type: 'bar',
        },

        "h-stack": {
            type: 'bar',
            orientation: 'h'
        }
    },
    "pie": {

        "pie": {
            type: 'pie'
        },
        "donut": {
            hole: .4,
            type: 'pie'
        },
        "funnel": {
            type: 'funnel',
            textposition: "inside", textinfo: "value+percent initial",
            hoverinfo: ' percent total+x',
            opacity: 0.65,
            connector: { line: { color: "grey", dash: "dot", width: 1 } }
        },

        "funnel-traditional": {
            type: 'funnelarea',
            orientation: 'v',
            showlegend: 'False',
            // textfont: {family: "Old Standard TT", size: 13, color: "black"},
            opacity: 0.65
        }
    }
}


export const chartDefaultLayout = {
    "line":{
        sparkline: false,
        toolbar: {
            show: false // Hide toolbar
          },
          zoom: {
            enabled: false // Disable zooming
          }
    }
}


