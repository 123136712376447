import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Layout, Collapse, Panel  ,  Modal, Input, Divider, Select, Segmented, Slider, Dropdown, Card, Switch, Radio ,  Tooltip, Space } from 'antd';
import { HomeOutlined , BarChartOutlined , DeleteOutlined, DownloadOutlined ,  NodeCollapseOutlined, NodeExpandOutlined , FunnelPlotOutlined, UpOutlined, DownOutlined, TableOutlined, BulbOutlined , PieChartOutlined, LineChartOutlined, CaretUpOutlined, DotChartOutlined, BarsOutlined, CaretDownOutlined } from '@ant-design/icons';
import Spreadsheet from "react-spreadsheet";
import DataFrame from "dataframe-js";
import { v4 as uuidv4 } from 'uuid';

import { useLocalStorage } from "@uidotdev/usehooks";

// import { loadFull } from 'tsparticles/confetti';

import {confetti} from '@tsparticles/confetti';

// import Plotly from 'plotly.js-dist';
import ApexCharts from 'apexcharts'

import html2canvas from 'html2canvas';
// import recorder from 'react-canvas-recorder';

import './index.css';
import { chartIcons , defaultDataToExplore ,   chartMetaDataDefault ,subChartAndIcons , subChart ,defaultLayout, defaultConfigSetting, gradients, colorsList} from './data.js'
import useSelection from 'antd/es/table/hooks/useSelection.js';

const Builder = (props) => {

    const [boardId, setBoardId] = useLocalStorage("boardId",'0000')
    const [boardName, setBoardName] = useLocalStorage("boardName",'Untitled Board')

    // const deafultDf = new DataFrame({
    //     column1: [23, 44, 21], // <------ A column
    //     column2: [100, 56, 79],
    // }, ['Age', 'Weight']);

    const [lineDf, setLineDf] = useState([]);
    console.log("-=-=-=--=-=-=-=-" , lineDf)
    const [xAxis, setXAxis] =  useState('Name');
    const [yAxis, setYAxis] = useState(['Age']);
    
    const [selectedYAxis, setSelectedYAxis] =  useState([])
    const [ySelectedProps, setYSelectedProps] = useState({})
    const [selectProps, setSelectedProps] =  useState({});
    const [chartData, setChartData] =  useState([])

    // chartData by page
    const[chartDataByPage , setChartDataByPage] = useState({})
    const [noDataSelected, setNodataSelected] = useState(true)
    const [isCollpased , setIsCollapsed] = useState(false)
    const [hidePages , setHidePages] = useState(false)
    const [selectedTab, setSelectedTab] = useState(1)
    const [spreadSheetData, setSpreadSheetData] =  useLocalStorage( `${boardId}-spreadSheetData` ,[])
   
    const [currentTheme, setCurrentTheme] = useState({})
    const [selectedThemeCategory, setSelectedThemeCategory] =  useState('auto')



    const [showThemes , setShowThemes] = useState()

    let selectedTheme = {
        plot_color: "#00bfff",
        bg: "#222222 ",
        pbc: "#333333",
        label: "#eeeeee",
        tick: "#cccccc",
        grid: "rgba(255,255,255,.2)",
    };

    const [shapedDataStored, setShapedDataStored] =  useLocalStorage( `${boardId}-shapedData` ,[])
    const [shapedData, setShapedData] =  useState([])

    const [chartFillData, setChartFillData] = useState()
    const [chartMetaData, setChartMetaData] = useState({...chartMetaDataDefault})

    const [pages , setPages]  =  useLocalStorage( `${boardId}-pages`,[])
    const [currentPage , setCurrentPage] =  useState()

    const [selectedChart, setSelectedChart] = useState('bar')
    const [selectedSubChart, setSelectedSubChart] = useState('h-bar')
    const [selectedBG, setSelectedBG] = useState({color:gradients["color"][0].gradient , theme: gradients["color"][0].theme, chartColor:[gradients["color"][14].gradient] })
    const [layoutByPage , setLayoutByPage] = useLocalStorage( `${boardId}-layoutByPage`,{})
    const [animationConfig , setAnimationConfig] = useState({animateWhen: 'after-plot', animateStyle:'party' , animateShape:'random' , animateDuration:3 })
    const [animationConfigByPage, setAnimationConfigByPage] = useLocalStorage( `${boardId}-animationConfigByPage`,{})
    
    const [chartConfigSettings, setChartConfigSettings] = useState({...defaultConfigSetting})
    const [chatConfigurationByPage, setChatConfigurationByPage] =  useLocalStorage( `${boardId}-chatConfigurationByPage`,{})
    const [selectedThemeCategoryByPage, setSelectedThemeCategoryByPage] = useLocalStorage( `${boardId}-selectedThemeCategoryByPage`,{})
    const [selectedChartCategoryByPage, setSelectedChartCategoryByPage] = useLocalStorage( `${boardId}-selectedChartCategoryByPage`,{})

    const [selectedBGByPage , setSelectedBGByPage] = useLocalStorage( `${boardId}-selectedBGByPage`,{})
    const [axisByPage , setAxisByPage] = useLocalStorage( `${boardId}-axisByPage`,{})
    const[barHorizontal , setBarHorizontal]  = useState('v')

    const [chartTitle, setChartTitle] = useState('')
    const [chartSubTitle, setChartSubTitle] = useState('')
    const DNDtype = 'PAGE'
    const chartContainerRef = useRef(null);

    const [gifUrl, setGifUrl] = useState('');
    const [mp4Url, setMp4Url] = useState('');

    //selected page layout
    const [layout, setLayout] = useState( {...defaultLayout} );
    const [prevSelectedChart, setPrevSelectedChart] = useState();
    const [prevAnimationConfig, setPrevAnimationConfig] = useState();
    const [prevSelectedBG, setPrevSelectedBG] = useState();
    const [download, setDownload] = useState(0)

    const [reAnimate, setReAnimate] = useState(0)
    const [currentPageNum , setCurrentPageNum ] = useState(1)
  
    
    useEffect(() => {
        
    },[shapedData])

    const handleDownload = async (format = 'png') => {
            
        let canvas = await html2canvas(chartContainerRef.current);
        setDownload(0)
        if (format === 'png') {
        const pngUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'my_chart.png';
        link.href = pngUrl;
        link.click();
        }
        // if (format === 'gif') {

        //     recorder.createStream(canvas);
        //     recorder.start();
        //     setReAnimate(reAnimate+1)
        //     setTimeout(() => {
        //         recorder.stop();
        //         console.log("Recording Stoped...")
        //         const file = recorder.save();
        //         console.log("File-----" , file)
        //     }, 5000); 
            


           
            // var gif = new GIF({
            //     workers: 2,
            //     quality: 10
            //   });
              
          
            //   gif.addFrame(canvas, {delay: 200});
              
            //   gif.on('finished', function(blob) {
            //     window.open(URL.createObjectURL(blob));
            //   });
              
            //   gif.render();

            // const gifEncoder = new gif();
            
            // gifEncoder.addFrame(canvas); // You can adjust the delay as needed
            // gifEncoder.on('finished', function(blob) {
            //     window.open(URL.createObjectURL(blob));
            //   const gifUrl = URL.createObjectURL(blob);
            //   setGifUrl(gifUrl);
            // });
            // gifEncoder.render();
            // console.log('Gif url----' , gifUrl)
        //   }
        // if (format === 'mp4') {
     

        //     // let recordRTC;
        //     // recordRTC = RecordRTC(canvas, { type: 'canvas' });
        //     // recordRTC.startRecording();
        //     // setReAnimate(reAnimate+1)
        //     // canvas = await html2canvas(chartContainerRef.current);
        //     // setTimeout(() => {
        //     //   recordRTC.stopRecording(() => {
        //     //     const blob = recordRTC.getBlob();
        //     //     const mp4Url = URL.createObjectURL(blob);
        //     //     console.log(mp4Url)
        //     //     setMp4Url(mp4Url);
        //     //   });
        //     // }, 5000); 
          
        //     // startRecording();

        //     // console.log( "=-=-=-=-=-= Canvas" ,canvas)


        //     // function startRecording() {
        //     // const chunks = []; // here we will store our recorded media chunks (Blobs)
            
        //     // const stream = canvas.captureStream(); // grab our canvas MediaStream
        //     // const rec = new MediaRecorder(stream); // init the recorder

            
        //     // rec.ondataavailable = e => {chunks.push(e.data) ; console.log("New data avialbe" , e.data)};
        //     // console.log("Recording started")
        //     // setReAnimate(reAnimate+1)
        //     // // only when the recorder stops, we construct a complete Blob from all the chunks
        //     // rec.onstop = e => exportVid(new Blob(chunks, {type: 'video/webm'}));
            
        //     // rec.start();
            
        //     // setTimeout(()=>{rec.stop();console.log("Recording Stoped")}, 10000); // stop recording in 3s
        //     // }

        //     // function exportVid(blob) {
        //     // const vid = document.createElement('video');
        //     // vid.src = URL.createObjectURL(blob);
        //     // // vid.controls = true;
        //     // // document.body.appendChild(vid);
        //     // // const a = document.createElement('a');
        //     // // a.download = 'myvid.webm';
        //     // // a.href = vid.src;
        //     // console.log("Video link ---", vid.src)
        //     // // a.textContent = 'download the video';
        //     // // document.body.appendChild(a);
        //     // console.log("Video Exported")
        //     // }


        //     // const canvasStream = canvas.captureStream(30); // Adjust frame rate as needed
        //     // const chunks = [];
        //     // const recorder = new MediaRecorder(canvasStream);
        //     // recorder.ondataavailable = (e) => chunks.push(e.data);
        //     // recorder.onstop = () => {
        //     //   const blob = new Blob(chunks, { type: 'video/mp4' });
        //     //   const mp4Url = URL.createObjectURL(blob);
        //     //   setMp4Url(mp4Url);
        //     //   console.log(mp4Url)
        //     // };
        //     // recorder.start();
        //     // setTimeout(() => recorder.stop(), 5000); // Stop recording after 5 seconds, adjust as needed
           
        //   }
        
    }


    
    useEffect( () => {
        if(download==0)
        return

        handleDownload('png')
    } , [download])

    const BuildChartData = (chart_fill_data_temp = [], selected_sub = '' , currentThemeColorsTemp = []) => {

     
        let currentThemeColors = currentThemeColorsTemp.map((value , index) => (value.code))
        let getColorByIndex = (index) =>{
            let currentColorByIndex = null
            try{
                currentColorByIndex =  currentThemeColors[index]
            }
            catch(error){

            }
            return currentColorByIndex
        }

        let XAxisTemp = lineDf.select(xAxis).toArray().flat()

        let data_temp = selectedYAxis.map((column ) => {
            let y_value  = []
            try {
                y_value = lineDf.select(column).toArray().flat()
                
                for (let i = 0; i < y_value.length; i++) {
                    const value = y_value[i];
                  
                    if (value === null) {
                      y_value[i] = 0; // Replace null values with 0
                    } else {
                      const numberValue = parseFloat(value); // Attempt conversion to float
                      if (isNaN(numberValue)) {
                        y_value[i] = value; // Keep string if conversion fails
                      } else {
                        y_value[i] = numberValue; // Store converted number (float)
                      }
                    }
                }              
            }
            catch(error){

            }
                return {
                    // x: XAxisTemp,
                    // y: {
                        name: column,
                        data: y_value
                    }
                // }
            }
            )

        if(selectedChart != undefined)
        {
            setSelectedChartCategoryByPage(prevState => ({
                ...prevState,
                [currentPage]: {chartType: selectedChart, subChart:selectedSubChart}
            }))

            let chartTypeTemp = {type: selectedChart , stacked: false}
            let fill = {}
            let series = data_temp

            if(selectedChart == 'stackedBar'){
                chartTypeTemp = {
                    type: 'bar', 
                    stacked: true
                }
            }
            let tempPlotOptions = {plotOptions:{ ...layout.plotOptions , bar : {
                ...layout.plotOptions.bar,
                horizontal:  false,
                isFunnel: false,

            }  }}
            if(selectedChart == 'stackedBar' || selectedChart == 'bar'){
                tempPlotOptions.plotOptions.bar = {
                    
                        ...layout.plotOptions.bar,
                        horizontal: barHorizontal == 'h' ? true : false,
                        isFunnel: false,
                    
                }
             }
            
            if (selectedChart == 'pie' || selectedChart =='donut'){
                series = data_temp[0].data
                // fill = {
                    // type: 'gradient',
                // }
            }

            
            if (selectedChart =='funnel'){
                chartTypeTemp = {
                    type: 'bar', 
                    stacked: false
                }
                tempPlotOptions.plotOptions.bar = {
                        ...layout.plotOptions.bar,
                        horizontal: true,
                        isFunnel: true,
                }
            }
            
            const options = {
                ...layout,
                series: series,
                labels:XAxisTemp,
                xaxis:{
                    ...layout.xaxis,
                    categories: XAxisTemp
                },
                chart:{
                    ...layout.chart,
                    ...chartTypeTemp , 
                    toolbar: { 
                        show: false
                      },
                    animations: {
                        enabled: true, 
                      },
                      isFunnel3d: true,
                    },
                    ...tempPlotOptions,
                    fill:{
                        ...layout.fill,
                        ...fill,

                    }
                
              };
            setLayout(options)
              
            //console.log("----- Options", options)
        }       

    }

    const [chartColor, setChartColor] = useState()

    useEffect(() => {
        if(pages.length < 1){
            let page_id = {"id":uuidv4()}
            setPages([page_id])
            setCurrentPage(page_id.id)
        } 
        
    }, [pages])

    useEffect(() => {
        console.log("Current PAge,.,.,.," , currentPage, selectedBGByPage[currentPage])

        if((currentPage == undefined || currentPage == null) && pages.length > 0 ){
            setCurrentPage(pages[0].id)
            
            return
        }        
        
        
        setLayout(layoutByPage[currentPage] == undefined ? {...layout}  : layoutByPage[currentPage]) 
        setChartConfigSettings(chatConfigurationByPage[currentPage] == undefined ? chartConfigSettings : chatConfigurationByPage[currentPage]  )
        

        // console.log("----- Current PAge update----------" , layoutByPage[currentPage] == undefined  ,  layoutByPage[currentPage] == undefined ? {...defaultLayout}  : layoutByPage[currentPage])
        if (axisByPage[currentPage] != undefined){
            // setXAxis(axisByPage[currentPage].x)
            setSelectedYAxis(axisByPage[currentPage].y)
        }

        if (selectedChartCategoryByPage[currentPage] != undefined){
            setSelectedChart(selectedChartCategoryByPage[currentPage].chartType)
            setSelectedSubChart(selectedChartCategoryByPage[currentPage].subChart)
        }
        if (selectedThemeCategoryByPage[currentPage] != undefined){
            setCurrentTheme(selectedThemeCategoryByPage[currentPage])
            setSelectedThemeCategory(selectedThemeCategoryByPage[currentPage].category)

        }
        if (selectedBGByPage[currentPage] != undefined){
            setSelectedBG(selectedBGByPage[currentPage])
        }
        if (animationConfigByPage[currentPage] != undefined){
            setAnimationConfig(animationConfigByPage[currentPage])
        }

    } ,
     [ currentPage] )

    useEffect( () => {
        if(layoutByPage == undefined || layoutByPage == null || currentPage == undefined ){
            return
        }
        ////console.log("--------- layout by page" , layoutByPage)
        setLayoutByPage(prevState => ({
            ...prevState,
            [currentPage]: layout
          }))

    } , [layout] )

    useEffect(() => {
        

        if(shapedData.length > 0) {
            console.log("-=-=-=- Returned from inital rendering.....")
            return
        }
        
        
        const reshapeDataForSpreadsheet = (data) => {
            // Create an array to hold the header row and data rows
            const shapedDataTemp = [];

            // Push the header row
            const headerRow = []
            for (let i = 0; i < 20; i++) {
                headerRow.push({ value: "" });
            }
            shapedDataTemp.push(headerRow);

            // Push the data rows
            const dataRows = []
            for (let i = 0; i < 50; i++) {
                const emptyRow = shapedDataTemp.map(() => ({ value: "" }));
                dataRows.push(emptyRow);
            }
            shapedDataTemp.push(...dataRows);

            return shapedDataTemp;
        }
        
        let intial_data = shapedDataStored.length > 0 ? shapedDataStored : defaultDataToExplore
        
        setShapedData(intial_data)
        SheetToFrame(intial_data)
    }, []);

    useEffect(() => {
        if (selectedBG == undefined){
            return
        }
       
        try{
        var plotlyChart = document.getElementById('chart-core');
        plotlyChart.style.background = selectedBG.color;
        }
        catch(error){


        }
    }, [selectedBG])

    function SheetToFrame(data) {

        console.log("---- Sheet to Frame" , data , data.length)
        
        if (data.length < 1) {
            return
        }
        // Convert data into a dataframe
        function convertToDataFrame(data) {

            const headers = data[0].reduce((acc, col) => {
                if (col.value !== "") {
                    acc.push(col.value);
                }
                return acc;
            }, []);

            const df_data = {}

            // ////console.log("---total-data" , data.length)
            let temp_all_null = false
            let temp_all_count = 0
            let temp_data_list = []
            // let prev_index;

            
            for (let i = 1; i < data.length; i++) {
               
                
                if (data[i].length < headers.length) {
                    for (let np = 0; np <= (headers.length - data[i].length); np++) {
                        data[i].push({ "value": "" })
                    }
                    
                }
                temp_all_null = false
                temp_all_count = 0
                temp_data_list = {}
                let prev_index = []
                data[i].forEach((col, index) => {
                    prev_index.push(index);
                    
                    if (headers[index]) {
                        if (!df_data[headers[index]]) {
                            df_data[headers[index]] = [];
                        }
                        try {

                            if (col == undefined || col.value == undefined) {
                                throw ("Undefiend cell")
                            }
                            // console.log("col value" , col.value)
                            if (col.value == "") {
                                temp_all_count++
                            }
                            if (xAxis != undefined && headers[index] == xAxis) {
                                if (col == undefined || col.value == "" || col.value == undefined) {
                                    // ////console.log("Returned" ,index ,   headers[index])
                                    return
                                }
                            }


                            // ////console.log(index , headers[index] , "----col.value ", col.value )
                            temp_data_list[headers[index]] = col.value == "" ? 0 : col.value;
                            // df_data[headers[index]].push(col.value == "" ? 0 : col.value);
                        }
                        catch (e) {
                            // console.log("Error", e)
                            temp_all_count++
                            temp_data_list[headers[index]] = null;

                        }

                    }
                });
                
                if (temp_all_count >= data[i].length) {
                    temp_all_null = true
                }
                
                if (temp_all_null == false && (temp_data_list[xAxis] != undefined && temp_data_list[xAxis] != null && temp_data_list[xAxis] != '')) {
                    
                    for (const item in headers) {
                        let key = headers[item]
                        // ////console.log(key,"-----" , headers)
                        if (!df_data[key]) {
                            df_data[key] = [];
                        }
                        if (temp_data_list.hasOwnProperty(key)) {
                            const value = temp_data_list[key];

                            df_data[key].push(value);
                        }
                        else {
                            if (key == xAxis) {
                                df_data[key].push('0');
                            }
                            else {
                                df_data[key].push(null);
                            }

                        }
                    }

                    // ////console.log( "Temp data" , temp_data_list)

                }
            }            
            return df_data;
        }
        const dataframe = convertToDataFrame(data);
        
        // setShapedData(data)
        setShapedDataStored(data)
        setSpreadSheetData(data)

        let df = new DataFrame(dataframe)
        
        setLineDf(df)
    }

    useEffect( () => {
        setAxisByPage(prevState => ({
            ...prevState,
            [currentPage]: {x: xAxis , y:selectedYAxis}
          }))
           
    } , [xAxis , selectedYAxis])

    useEffect( () => {
        //console.log("Changed config", animationConfig)
        setAnimationConfigByPage (prevState => ({
            ...prevState,
            [currentPage]: animationConfig
          }) ) 
    },[animationConfig])

    useEffect( () => {
        setChatConfigurationByPage(prevState => ({
            ...prevState,
            [currentPage]: {...chartConfigSettings}
          }))
    } , [chartConfigSettings]) 


    useEffect(() => {

        
        let loadDf;
        if (lineDf.length == 0) {
            return
        }

        else{
            loadDf = lineDf
        }

        const columns = lineDf.listColumns()

        const x_axis = columns[0]
        setXAxis(x_axis)

        const y_axis = columns.slice(1).map((header) => ({
            label: header,
            value: header,
        }))

        setYAxis(y_axis);

        setYSelectedProps({
            mode: 'multiple',
            options: y_axis,
            placeholder: 'Select Y axis',
            maxTagCount: 'responsive',
        })

        const y_selected_values = columns.slice(1).map((header) => (
            header
        ))

        // console.log("--------- Line df chage" )

        setSelectedYAxis(y_selected_values)
       
        setChartConfigSettings({ ...chartConfigSettings, yaxis: y_selected_values, xaxis: x_axis })
        setSelectedProps({ onChange: setSelectedYAxis, "selectedYAxis": y_selected_values })
        


    }, [lineDf , selectedThemeCategory ])


    useEffect( () => {
       
        if(currentTheme != undefined){
            setSelectedThemeCategoryByPage(prevState => ({
                ...prevState,
                [currentPage]: {...currentTheme}
              }))
            
        }
        
    } , [currentTheme])


    useEffect(() => {
        
        if (selectedYAxis.length < 1 || selectedSubChart == null || selectedSubChart == '') {
            setChartData([])

            let temp_layout = {...layout}
            delete temp_layout.yaxis.type;
            delete temp_layout.xaxis.type;
            setLayout(temp_layout)
            setNodataSelected(true)
            return
        }

        try {
            setNodataSelected(false)
            let data_temp = BuildChartData()
            setChartData(data_temp)

        }
        catch (error) {
        }


    }, [selectedYAxis, chartMetaData, currentTheme, selectedChart , barHorizontal])

    useEffect( () => {
        console.log("-=-=-=-=-=-= lineDf changes" , lineDf.toLocaleString() )
    }, [lineDf]) 

    useEffect(() => {
        
        // if (noDataSelected) {
        //     return 
        // }
        try {
            
            const chartContainer = document.querySelector('#chart-core');
            chartContainer.innerHTML = '';
            if (chartContainer.hasChildNodes()) {
                const existingChart = chartContainer.querySelector('.apexcharts-canvas');
                console.log("Existing Charts......" , existingChart)
                if (existingChart) {
                  existingChart.parentNode.removeChild(existingChart);
                }
                try{

                    if (chart) { 
                        chart.destroy();
                        }
                } catch(e){

                }
                
              }

            let showAnimationNow = false

            if (selectedChart !== prevSelectedChart || animationConfig != prevAnimationConfig || selectedBG != prevSelectedBG  ) {
                console.log("------- Chart Changed" , selectedChart , prevSelectedChart)
                setPrevSelectedChart(selectedChart)
                setPrevAnimationConfig(animationConfig)
                setPrevSelectedBG(selectedBG)
                showAnimationNow = true
            }
    
              let temp_layout = {...layout}

              temp_layout.theme.monochrome.color = selectedBG.chartColor[0] || temp_layout.theme.monochrome.color
              temp_layout.theme.mode = selectedBG.theme || temp_layout.theme.mode
              temp_layout.chart = {
                ...temp_layout.chart,
                events:{
                    ...temp_layout.chart.events,
                    beforeMount: () => {
                        if( animationConfig.animateWhen =='before-plot' && showAnimationNow == true ){
                            triggerConfetti()
                        }
                     },
                    animationEnd: () => {
                            if( animationConfig.animateWhen =='after-plot' && showAnimationNow == true){
                                triggerConfetti()
                            }
                    },

                }
              }
            console.log("Temp layout.......", temp_layout)
            const chart = new ApexCharts(chartContainer, {...temp_layout});
            console.log('Rendering chart...')
            chart.render();
          } catch (e) {
            console.log("Chart rendering issue:", e);
          }
    }, [chartData,  animationConfig, selectedBG, layout])


    const triggerConfetti = async () => {
        const canvas =   document.getElementById("chart-confetti");
        // const canvas = document.getElementsByClassName("chart-options-container")[0]

        const chartConfetti = document.getElementById("chart-confetti");

        const chartCore = document.getElementById("chart-core");
        const chartCoreRect = chartCore.getBoundingClientRect();


        chartConfetti.style.position = 'absolute';
        chartConfetti.style.width = chartCoreRect.width + 'px';
        chartConfetti.style.height = chartCoreRect.height + 'px';

        chartConfetti.style.zIndex = '4';

        chartConfetti.style.pointerEvents = 'none';

        if (!canvas) {
            return; 
          }

          canvas.confetti =
          canvas.confetti || (await confetti.create(canvas, { resize: true }));
          const duration = animationConfig.animateDuration * 1000
          
          const end = Date.now() + duration;
          
        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
            }

            let timeLeft = 300


            // go Buckeyes!
            const colors = ["#bb0000", "#ffffff"];
            
            let skew = 1;
            let frame = () => {
                const timeLeft = end - Date.now(),
                ticks = Math.max(200, 500 * (timeLeft /  duration));
            
                skew = Math.max(0.8, skew - 0.001);

                const particleCount = 20 * (timeLeft / duration);

                const shapes = {
                    "random": [],
                    "love": ["❤️" , "💚","🫰","🫶","🤍"],
                    "money": ["💸","💰","💵", "🪙"],
                    "like": ["👍","👌"],
                    "fire":["🔥","💥","⚡"]
                }
                let final_shape = {}
                
                if(animationConfig.animateShape == 'random'){
                    final_shape =  {shapes: ["circle", "square", "heart"]}
                }
                else{
                    final_shape =  {shapes: ["emoji"],
                        shapeOptions: {
                        emoji: {
                            value: shapes[animationConfig.animateShape],
                        },
                        }
                    }
                }

                //console.log("------final shape" , final_shape)
                

                const confettiStyle = {
                    "party": [{
                        spread: 100,
                        ticks: 50,
                        gravity: 0,
                        decay: 0.94,
                        startVelocity: 10,
                        colors,
                        ...final_shape,
                        // shapes: ["circle" ,"square", "Triangle", "emoji"],
                        // colors: ["FFC0CB", "FF69B4", "FF1493", "C71585"],
                        particleCount: 10,
                        scalar: 1,
                      },
                      {
                        spread: 100,
                        ticks: 50,
                        gravity: 0,
                        decay: 0.94,
                        startVelocity: 10,
                        colors,
                        ...final_shape,
                        particleCount: 10,
                        scalar: 1.5,
                      }
                      ],
                    "firework": [
                       
                        {
                            startVelocity: 30, spread: 20, ticks: 60, zIndex: 0,
                            particleCount,
                            colors,
                            ...final_shape,
                            scalar:1,
                            origin: { x: randomInRange(0.5, 0.9), y: Math.random() - 0.2 },
                          },
                          {
                            startVelocity: 30, spread: 20, ticks: 60, zIndex: 0,
                            particleCount,
                            colors,
                            scalar:1.5,
                            ...final_shape,
                            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
                          }
                          
                    ],
                    "fall": [
                        {
                            particleCount: 10,
                            startVelocity: 0,
                            ticks: ticks,
                            shapes: final_shape,
                            origin: {
                              x: Math.random(),
                              y: Math.random() * skew - 0.2,
                            },
                            colors,
                            scalar:1,
                            ...final_shape,
                            gravity: randomInRange(0.4, 0.6),
                            scalar: randomInRange(0.4, 1),
                            drift: randomInRange(-0.4, 0.8),
                          },
                          {
                            particleCount: 10,
                            startVelocity: 0,
                            ticks: ticks,
                            shapes: final_shape,
                            origin: {
                              x: Math.random(),
                              y: Math.random() * skew - 0.2,
                            },
                            colors,
                            scalar:1.5,
                            ...final_shape,
                            gravity: randomInRange(0.4, 0.6),
                            scalar: randomInRange(0.4, 1),
                            drift: randomInRange(-0.4, 0.8),
                          }
                    ],
                    "celebrate": [{
                        particleCount: 2,
                        angle: 60,
                        ...final_shape,
                        spread: 55,
                        scalar:1,
                        origin: { x: 0 },
                        colors: colors,
                    }, {
                        particleCount: 2,
                        ...final_shape,
                        angle: 120,
                        spread: 55,
                        scalar:1.5,
                        origin: { x: 1 },
                        colors: colors,
                    }]
                }
                
                const confettiStyles = confettiStyle[animationConfig.animateStyle];
                confettiStyles.forEach(style => {
                    canvas.confetti(style);
                });
            if (Date.now() < end) {
                if (animationConfig.animateStyle != 'celebrate'){
                    setTimeout(() => {
                        requestAnimationFrame(frame);
                    }, 500);
                }
                else{
                    requestAnimationFrame(frame);
                }
                
                
            }
            };
            frame()


    };


    const updateChartSettings = (dict) => {

        console.log("-------- Updating chart setting")
        setChartConfigSettings({ ...chartConfigSettings, ...dict })

        let meta_data_copy = JSON.parse(JSON.stringify(chartMetaData))
        for (const key in dict) {
            if (key == "yaxis") {
                setSelectedYAxis(dict[key])
            }
            if (key == "xais") {
                setXAxis(dict[key])
            }
            if (key == "lineMarker") {

                if (dict[key] == 'none' || dict[key] == null) {
                    meta_data_copy.line.line.mode = "lines"
                    meta_data_copy.line.stack.mode = "lines"
                }
                else {
                    meta_data_copy.line.line.marker.symbol = dict[key]
                    meta_data_copy.line.scatter.marker.symbol = dict[key]
                    meta_data_copy.line.stack.marker.symbol = dict[key]
                    meta_data_copy.line.stack.mode = "lines+markers"
                    meta_data_copy.line.line.mode = "lines+markers"
                }

            }
            if (key == "lineWidth") {
                meta_data_copy.line.line.line.width = dict[key]
                meta_data_copy.line.stack.line.width = dict[key]
            }
            if (key == "donutSize") {
                meta_data_copy.pie.donut.hole = dict[key] / 10
            }
        }

        setChartMetaData(meta_data_copy)


    }

   

    const changeChartBG= (item) =>{
        setSelectedBG({'color':item.gradient , 'theme':item.theme , "chartColor": item.chartColor })
        setSelectedBGByPage(prevState => ({
            ...prevState,
            [currentPage]: item
          }))
    }

    const ChartConfigurations = () => {

        return <div className='data-chart-box'>{
            (selectedChart == 'line' || selectedChart == 'area' ) &&
             <div>
                 <div className='data-configuration'>
                     <p className='dc-title'>Data Configuration</p>
                     <div className='x-y-aixs'>
                         <div className='axis x'>
                             <p>X Axis</p>
                             <Select
                                 placeholder={'Select X Axis'}
                                 defaultValue={chartConfigSettings.xaxis}
                                 style={{ width: '100%' }}
                                 onChange={(value) => { updateChartSettings({ "xaxis": value }) }}
                                 options={[
                                     { value: xAxis, label: xAxis },
                                 ]}
                             />

                         </div>

                         <div className='axis'>
                             <p>Y Axis</p>
                             <Select  {...ySelectedProps} {...selectedYAxis} onChange={(value) => {
                                 // setSelectedYAxis
                                 updateChartSettings({ "yaxis": value })

                             }} value={chartConfigSettings.yaxis} />
                         </div>
                         
                        


                     </div>
                     <div className='line-setting'>
                            <div >
                                <p>Data Labels</p>
                                <Switch value={layout.dataLabels.enabled} onChange={(e) => { setLayout({ ...layout, dataLabels: { ...layout.dataLabels, enabled: e } }) }} className='switch' />
                            </div>
                         {/* {selectedSubChart != "scatter" && <div className='slider'>
                             <p>Line width</p>
                             <Slider min={0} max={5} value={chartConfigSettings.lineWidth} onChange={(value) => {
                                 updateChartSettings({ "lineWidth": value })
                             }}
                             />
                         </div>
                         } */}

                     </div>
                 </div>

             </div>
         }


         { (selectedChart == 'bar' || selectedChart == 'stackedBar' ) &&
             <div>
                 <div className='data-configuration'>
                     <p className='dc-title'>Data Configuration</p>
                     <div className='x-y-aixs'>
                         <div className='axis x'>
                             <p> {selectedSubChart.includes('h-') ? 'Y Axis' : 'X Axis'}</p>
                             <Select
                                 placeholder={'Select ' + selectedSubChart.includes('h-') ? 'Y Axis' : 'X Axis'}
                                 defaultValue={chartConfigSettings.xaxis}
                                 style={{ width: '100%' }}
                                 onChange={(value) => { updateChartSettings({ "xaxis": value }) }}
                                 options={[
                                     { value: xAxis, label: xAxis },
                                 ]}
                             />

                         </div>

                         <div className='axis'>
                             <p>{selectedChart == 'bar' ? 'Categories' : 'Y Axis'}</p>
                             <Select  {...ySelectedProps} {...selectedYAxis} onChange={(value) => {
                                 // setSelectedYAxis
                                 updateChartSettings({ "yaxis": value })

                             }} value={chartConfigSettings.yaxis} />
                         </div>



                     </div>
                     <div className='axis'>
                             <p>Orientation</p>
                             <Select defaultValue={barHorizontal} onChange={(e) => { setSelectedChart(selectedChart) ; setBarHorizontal(e)}} options={[{'label':'Horizontal' , 'value':'h'} , {'label':'Vertical' , 'value':'v'}] } />
                    </div>
                    <div style={{marginBottom:'10px'}}> </div>
                 </div>

             </div>
         }


         { (selectedChart == 'pie' || selectedChart == 'donut' || selectedChart == 'funnel') &&
             <div>

                 <div className='data-configuration'>
                     <p className='dc-title'>Data Configuration</p>
                     <div className='x-y-aixs'>
                         <div className='axis x'>
                             <p>Labels</p>
                             <Select
                                 placeholder={'Select Labels'}
                                 defaultValue={chartConfigSettings.xaxis}
                                 style={{ width: '100%' }}
                                 onChange={(value) => { updateChartSettings({ "xaxis": value }) }}
                                 options={[
                                     { value: xAxis, label: xAxis },
                                 ]}
                             />

                         </div>

                         <div className='axis'>
                             <p>Values</p>
                             <Select
                                 placeholder={"Select Value"}
                                 {...ySelectedProps} {...selectedYAxis} onChange={(value) => {
                                     // setSelectedYAxis
                                     updateChartSettings({ "yaxis": [value] })

                                 }} value={chartConfigSettings.yaxis.length > 0 ? chartConfigSettings.yaxis : "Select Value"} mode='none' maxCount={1} />
                         </div>
                         {
                             selectedSubChart == 'donut' &&
                             <div className='slider'>
                                 <p>Donut Size</p>
                                 <Slider value={chartConfigSettings.donutSize} onChange={(value) => { updateChartSettings({ "donutSize": value }) }} min={0} max={8} />
                             </div>
                         }
                         {
                             (selectedSubChart == 'funnel' || selectedSubChart == 'funnel-traditional') &&

                             <div>
                                 <div className='slider'>
                                     <p>Reverse</p>
                                     <Switch value={chartConfigSettings.reverseFunnel} onChange={(value) => { updateChartSettings({ "reverseFunnel": value }) }} />
                                 </div>
                             </div>
                         }

                     </div>
                 </div>



             </div>
         }

        


         <Divider dashed style={{ width: '80%', minWidth: '0px', margin: '0 auto', borderColor: "rgba(255,255,255,.4)" }} />
         <div className='layout-configuration'>
             <p className='dc-title'>Layout Configuration</p>
             <div className='layout-settings'>
                 <Card
                     title="Chart Title & Description"

                     style={{
                         width: '100%',
                     }}
                 >
                     <div className='title-title-left input'>
                         {/* <Switch className='switch' /> */}
                         <p>Title</p>
                         <Input value={layout.title.text} onChange={(e) => { setLayout({ ...layout, title: { ...layout.title, text: e.target.value } }) } } placeholder='Title' />
                     </div>
                     <div className='title-title-left input'>
                         {/* <Switch className='switch' /> */}
                         <p>Sub title</p>
                         <Input value={layout.subtitle.text} onChange={(e) => { setLayout({ ...layout, subtitle: { ...layout.subtitle, text: e.target.value } }) } } placeholder='Sub title' />
                     </div>

                     {/* <div className='title-title-left input'>
                         <Switch className='switch' />
                         <p>Description</p>
                         <Input placeholder='Description' />

                     </div> */}

                 </Card>
                 <Card
                     title="X Axis Configurations"

                     style={{
                         width: '100%',
                     }}
                 >
                     <div className='title-title-left input'>
                         {/* <Switch className='switch' /> */}
                         <p>Label</p>

                         <Input defaultValue={layout.xaxis.title.text} onChange={(e) => setLayout({ ...layout, xaxis: { ...layout.xaxis, title: { ...layout.xaxis.title, text: e.target.value } } })} placeholder='X Label' />

                     </div>
                     
                     <div className='title-title-left'>
                         <p>Labels</p>
                         <Switch value= {layout.xaxis.labels.show} onChange={(e) => { setLayout({ ...layout, xaxis: { ...layout.xaxis, labels: {...layout.xaxis.labels, show: e} ,  axisTicks:{ ...layout.xaxis.axisTicks , show: e }  } }) } } className='switch' />
                     </div>
                     {/* <div className='title-title-left'>
                         <p>Ticks</p>
                         <Switch value={layout.xaxis.axisTicks.show} onChange={(e) => { setLayout({ ...layout, xaxis: { ...layout.xaxis,  axisTicks:{ ...layout.xaxis.axisTicks , show: e } } } ) }} className='switch' />
                     </div> */}
                     <div className='title-title-left'>
                         <p>Grid Line</p>
                         <Switch value={layout.grid.xaxis.lines.show} onChange={(e) => { setLayout({ ...layout, grid: { ...layout.grid, xaxis: {...layout.grid.xaxis , lines:{ ...layout.grid.xaxis.lines, show: e } } } }) }} className='switch' />
                     </div>
                     <div className='title-title-left'>
                         <p>Axis Line</p>
                         <Switch value= {layout.xaxis.axisBorder.show} onChange={(e) => { setLayout({ ...layout, xaxis: { ...layout.xaxis, axisBorder: {...layout.xaxis.axisBorder, show: e} } }) } } className='switch' />
                     </div>
                    
                 </Card>
                 <Card
                     title="Y Axis Configurations"

                     style={{
                         width: '100%',
                     }}
                 >
                     <div className='title-title-left input'>
                         {/* <Switch className='switch' /> */}
                         <p>Label</p>

                         <Input value={layout.yaxis.title.text} onChange={(e) => setLayout({ ...layout, yaxis: { ...layout.yaxis, title: { 
                            // ...layout.yaxis.title,
                             text: e.target.value } } })} placeholder='Y Label' />

                     </div>
                     <div className='title-title-left'>
                         <p>Labels</p>
                         <Switch value= {layout.yaxis.labels.show} onChange={(e) => { setLayout({ ...layout, yaxis: { ...layout.yaxis, labels: {...layout.yaxis.labels, show: e} ,  axisTicks:{ ...layout.yaxis.axisTicks , show: e }  } }) } } className='switch' />
                     </div>
                     {/* <div className='title-title-left'>
                         <p>Ticks</p>
                         <Switch value={layout.yaxis.axisTicks} onChange={(e) => { setLayout({ ...layout, yaxis: { ...layout.yaxis, showticklabels: e } }) }} className='switch' />
                     </div> */}
                     <div className='title-title-left'>
                         <p>Grid Line</p>
                         <Switch value={layout.grid.yaxis.lines.show} onChange={(e) => { setLayout({ ...layout, grid: { ...layout.grid, yaxis: {...layout.grid.yaxis , lines:{ ...layout.grid.yaxis.lines, show: e } } } }) }} className='switch' />
                     </div>
                     {/* <div className='title-title-left'>
                         <p>Axis Line</p>
                         <Switch value={layout.yaxis.axisBorder.show} onChange={(e) => { setLayout({ ...layout, yaxis: { ...layout.yaxis, axisBorder: {...layout.yaxis.axisBorder, show: e} } }) }} className='switch' />
                     </div> */}
                 </Card>

                 <Card
                     title="Others"

                     style={{
                         width: 270,
                     }}
                 >
                     <div className='title-title-left'>
                         <p>Legends</p>
                         <Switch value={layout.legend.show} onChange={(e) => setLayout({ ...layout,  legend: { ...layout.legend, show: e }   })} className='switch' />
                     </div>



                 </Card>


             </div>
         </div>
     </div>
    }

    const ChartTypes = () => {
        return <div className='data-chart-box'>
                 <div className='chartIcons'>
        {chartIcons.map((chartIcon) => (
          <div onClick={() => setSelectedChart(chartIcon.name) } key={chartIcon.name} className={selectedChart == chartIcon.name ? 'chart-icon-wrapper active-chart' : 'chart-icon-wrapper' }>
            <img  className='active' src={chartIcon.icon} alt={chartIcon.name} />
          </div>
        ))}
      </div>
            </div>
    }

    const ChartBackgrounds = (props) => {
        //console.log("------------- props.type" , props)
        return <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}> 
        {gradients[props.type].map((item, index) => (
            <div
            key={index}
            onClick = { () =>  {
                    changeChartBG({...selectedBG,...item})
            }}
            className="gradient-box"
            style={{ cursor:'pointer', textAlign:'center' ,background: item.gradient, width: '50px', height: '50px', margin: '10px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  
                border: selectedBG.color === item.gradient ? '2px solid #FF0000' : 'none' }}
          >
     
          </div>
      )
       )}
       </div>
    }

    const ChartColors = (props) => {
        //console.log("------------- props.type" , props)
        return <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}> 
        {gradients[props.type].map((item, index) => (
            <div
            key={index}
            onClick = { () =>  {
                
                changeChartBG({ ...selectedBG, 'chartColor': [item.gradient]})
                
                
            }}
            className="gradient-box"
            style={{ cursor:'pointer', textAlign:'center' ,background: item.gradient, width: '50px', height: '50px', margin: '10px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            border: selectedBG.chartColor[0] === item.gradient ? '2px solid #FF0000' : 'none' }}
          >
        
          </div>
      )
       )}
       </div>
    }


    const AnimationConfiguration = () => {
        return <div className='animation-configuration'>
                <Radio.Group onChange={(e) => { setAnimationConfig({...animationConfig , animateWhen:e.target.value}) }} defaultValue={animationConfig.animateWhen} >
                    <Radio.Button value="no-animation">No Animation</Radio.Button>
                    <Radio.Button value="before-plot">Before Plot</Radio.Button>
                    <Radio.Button value="after-plot">After Plot</Radio.Button>
                </Radio.Group>
                <div style={{margin:'10px'}}></div>
                <h4>Animation Duration(in sec)</h4>
                <Slider onChange={(value) => { setAnimationConfig({...animationConfig , animateDuration:value}) }}  style={{width:'80%'}} value={animationConfig.animateDuration} max={15} />
                <div style={{margin:'5px'}}></div>
                <h4>Animation Type</h4>
                <Radio.Group onChange={(e) => { setAnimationConfig({...animationConfig , animateStyle:e.target.value}) }} defaultValue={animationConfig.animateStyle}>
                    <Radio.Button value="party">Party</Radio.Button>
                    <Radio.Button value="firework">Firework</Radio.Button>
                    <Radio.Button value="fall">Snow Fall</Radio.Button>
                    <Radio.Button value="celebrate">Celebrate</Radio.Button>
                </Radio.Group>
                <div style={{margin:'10px'}}></div>
                <h4>Animation Shapes</h4>
                <Radio.Group onChange={(e) => { setAnimationConfig({...animationConfig , animateShape:e.target.value}) }} defaultValue={animationConfig.animateShape}>
                    <Radio.Button value="random">Random</Radio.Button>
                    <Radio.Button value="love">Love</Radio.Button>
                    <Radio.Button value="money">Money</Radio.Button>
                    <Radio.Button value="like">Likes</Radio.Button>
                    <Radio.Button value="fire">Fire</Radio.Button>
                </Radio.Group>
  
        </div>
    }

    const chartConfigPanels = [
        {
          key: '1',
          label: 'Select your chart type!',
          children: ChartTypes(),
        },
        {
          key: '2',
          label: 'Data Configurations',
          children: ChartConfigurations(),
        },
        {
            key: '3',
            label: 'Animations',
            children: AnimationConfiguration(),
        }
      ];

      const chartBGPanels = [
        {
            key: '0',
            label: 'Chart Colors',
            children: <ChartColors type={'color'} segment={'chartColor'}/>,
          },
        {
          key: '1',
          label: 'Colors',
          children: <ChartBackgrounds type={'color'}/>,
        },
        {
          key: '2',
          label: 'Gradients',
          children: <ChartBackgrounds type={'gradient'}/>,
        }
      ];

    return (
        <div>
            <Layout className="layout-container">
                <div className='header'>
                <div className='left-side'>
                     {/* <HomeOutlined className='home-icon'/> */}
                    
                        {/* <div>List Dashboard</div> */}
                        <div className='logo'>No Code Charts</div>
                        <Tooltip placement="bottom" title={isCollpased == true ? 'Expand Data & Charts' : 'Hide data & Charts'}>        
                        <div className='collapse-data' onClick={() => {setIsCollapsed(!isCollpased)}}>{isCollpased == true ?  <NodeExpandOutlined /> :  <NodeCollapseOutlined/>}</div>
                        </Tooltip>
                    </div>
                    <div className='center'>
                        <Input placeholder='Dashboard Name' className='board-name' value={boardName} onChange={(e) => {setBoardName(e.target.value)}} />
                    </div>
                    <div className='right-side'>
                        <div title='coming-soon' className='share'>Share</div>
                        <div title='coming-soon' className='profile'>Profile</div>
                    </div>
                    
                </div>
                <Row gutter={8} className="chart-preview-container" style={{marginTop:isCollpased !=true?'40px':0}}>
                {/* <div className='collapsible-vertical'> */}
                {/* <div class="collapsible-header">
                        <i class="arrow-icon">^</i>
                </div> */}
                <Col span={isCollpased ==true ? 0 : 6} className="chart-options">
                       
                        <div className="chart-options-container">
                            <div className='chart-data-switch'>
                                <p className={selectedTab == 0 ? 'active' : ''} onClick={() => setSelectedTab(0)}>Data</p>                               
                            </div>


                            <div className='data-chart-box'>
                                <Spreadsheet Mode={'edit'} rowLabels={Array.from({ length: shapedData.length }, (_, index) => index)} hideColumnIndicators={true} onChange={(data) => SheetToFrame(data)} darkMode={true} data={shapedData} />
                                {/* <p>Add more rows</p> */}
                            </div>

                        </div>
                {/* </div> */}
                </Col>
                {/* </div> */}
                {/* <Col span={isCollpased ==true ? 2 : 0} > Expand </Col> */}
                <Col span={10} offset={isCollpased ==true ? 0 : 1} style={{ height: '100%', backgroundColor: 'transparent' }}>
                        
                        <div className='chart-preview'  >
                       
                        <div className='chart-meta-options'  >
                            <p>Page ({currentPageNum} / {pages.length})</p>
                            <Tooltip placement="bottom" title='Download chart'>        
                            <DownloadOutlined style={{padding:'10px'}} onClick={() => {setDownload(download+1)}} />
                            </Tooltip>
                             {/* <p>Share</p></div> */}
                        </div>
                            <div className='chart-layer' style={{position:'relative', width:'100%', height:'70%'}}>
                                <div id='chart-confetti' style={{position:'absolute'}}></div>
                                <div ref={chartContainerRef} className='chart-core' id="chart-core"></div>
                            
                            </div>
                            
                            <div className='collapsible' style={{width:'100%'}}>
                            <div class="collapsible-header">
                                <i class="arrow-icon" onClick={()=> {setHidePages(!hidePages)}}>{ hidePages == true ? <UpOutlined /> : <DownOutlined />} </i>
                            </div>
                            
                            <div style={{display: hidePages == true ? 'none': 'flex'}} class="chart-pages collapsible-content">
                        
                            {pages.map((item , index) => 
                            
                            
                            
                            <div className= {item.id == currentPage ? 'page active-page'  :'page' } onClick={() => { setCurrentPage(item.id);setCurrentPageNum(index+1) } }>
                                    
                                    <span className='delete-page' onClick={() => {
                                        
                                        let pages_temp = [...pages]
                                        pages_temp.splice(index,1)
                                        setPages(pages_temp)
                                        setCurrentPageNum(index)
                                        
                                    }}><DeleteOutlined /></span>
                                    <div> {subChartAndIcons[selectedChartCategoryByPage[item.id]]!= undefined ? subChartAndIcons[selectedChartCategoryByPage[item.id]]["category"] : <div><BulbOutlined /></div>} </div>

                                </div>
                            )}
                        { pages.length < 5 &&  <div className='page add-new-page' onClick={() => {
                            let page_id = uuidv4()
                            setPages([...pages, {"id":page_id}])
                            setCurrentPage(page_id)
                            setCurrentPageNum(pages.length+1)
                            }}>+</div> }

                            </div>
                            
                            </div>
                           
                        </div>
                        

                    </Col>
                    
                    <Col offset={isCollpased ==true ? 0 : 1}  span={isCollpased ==true ? 0 : 6} className="chart-options">
                        {/* <ChartSettingDiv/> */}
                        <div className="chart-options-container">
                            <div className='chart-data-switch'>
                                <p className={selectedTab == 1 ? 'active' : ''} onClick={() => setSelectedTab(1)}>Chart</p>                              
                                <p className={selectedTab == 2 ? 'active' : ''} onClick={() => setSelectedTab(2)}>Background</p>
                            </div>
                                {
                                    selectedTab == 1 && <Collapse items={chartConfigPanels} defaultActiveKey={['1']} />
                                }
                                {
                                    selectedTab == 2 && <Collapse items={chartBGPanels} defaultActiveKey={['1']} />
                                }
                        </div>
                    </Col>
                </Row>
            </Layout>
        </div>
    );
};

export default Builder;
