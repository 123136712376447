import logo from './logo.svg';
import './App.css';
import { ConfigProvider, theme } from 'antd';

import Builder from './comps/chat-builder/index.js';


function App() {
  return (
    <ConfigProvider
    theme={{

      // 1. Use dark algorithm
      algorithm: theme.darkAlgorithm,
        components: {
          Collapse: {
            headerBg: "rgba(67,67,67,1)"
          },
        
    }
  }}
  >
  
    <div className="App">
      <Builder/>
    </div>
    </ConfigProvider>
  );
}

export default App;
